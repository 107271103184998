/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./components/header"

import "./layout.css"

import Footer from "./components/footer"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className={"bg-gray-100"}>
        <main
          className="container shadow-md"
          style={{
            margin: `0 auto`,
            maxWidth: 1024,
            // padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
            backgroundColor: "#ededed",
          }}
        >
          {children}
        </main>
        <Footer siteTitle={data.site.siteMetadata.title}>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </Footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
