import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"

import styles from "./header.module.css"
import Logo from "../components/logo"

const Header = ({ siteTitle }) => {
  const [navCollapsed, setNavCollapsed] = useState(true)

  return (
    <header
      style={{
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1024,
          padding: `1.25rem 1.0875rem`,
          // display: `flex`,
          // justifyContent: `space-between`,
        }}
        className={styles.header}
      >
        <Link
          to="/"
          style={{
            color: `#000`,
            textDecoration: `none`,
            flex: 1,
          }}
        >
          <h1 style={{ margin: 0, display: "block", alignItems: "center" }}>
            <Logo></Logo>
          </h1>
        </Link>

        <div
          className={styles.hamburger}
          style={{
            position: "absolute",
            right: "2em",
            top: "2em",
          }}
        >
          <FontAwesomeIcon
            icon={faBars}
            onClick={() => setNavCollapsed(!navCollapsed)}
            style={{ color: "#C42C00" }}
            size="2x"
          />
        </div>

        <ul
          style={{
            // display: `flex`,
            // textDecoration: `none`,
            // listStyle: `none`,
            // alignItems: "center",
            margin: 0,
          }}
          className={
            navCollapsed ? styles.nav + " " + styles.collapsed : styles.nav
          }
        >
          <Link to="/" className={styles.navLink}>
            <li className="navLink">Start</li>
          </Link>

          <Link to="/priser/" className={styles.navLink}>
            <li className="navLink">Priser</li>
          </Link>

          <Link to="/utbildningar/" className={styles.navLink}>
            <li className="navLink">Utbildningar</li>
          </Link>
          <Link to="/kontakt/" className={styles.navLink}>
            <li className="navLink">Kontakt</li>
          </Link>
        </ul>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
